import React from 'react'

import Layout from '../../components/Layout'
import {
  Wrapper,
  Content,
  Header,
  SubHeader,
  CardWrapper,
  BrowserCard,
  BrowserName,
} from './styledComponents'

const BROWSERS = [
  {
    name: 'Chrome',
    imageUrl:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1642704012/prototypes/ie-deprecation-page/chrome_256x256.png',
    link: 'https://www.google.com/chrome/',
  },
  {
    name: 'Edge',
    imageUrl:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1642704012/prototypes/ie-deprecation-page/edge_256x256.png',
    link: 'https://www.microsoft.com/en-us/edge',
  },
  {
    name: 'Firefox',
    imageUrl:
      'https://res.cloudinary.com/pulsedatatools/image/upload/v1642704012/prototypes/ie-deprecation-page/firefox_256x256.png',
    link: 'https://www.mozilla.org/en-US/firefox/new/',
  },
]

const LayoutBrowserDeprecation = () => (
  <Layout>
    <Wrapper>
      <Content>
        <Header>
          The Internet Explorer browser is no longer supported by Pulse
          Analytics.
        </Header>
        <SubHeader>
          Please upgrade to one of the latest versions of the following browsers
          to continue using the tool.
        </SubHeader>
        <CardWrapper>
          {BROWSERS.map(({ name, imageUrl, link }) => (
            <BrowserCard href={link} target="_blank" rel="noreferrer">
              <img
                alt={`${name}-logo`}
                src={imageUrl}
                style={{ height: 48, width: 48 }}
              />
              <BrowserName>{name}</BrowserName>
            </BrowserCard>
          ))}
        </CardWrapper>
      </Content>
    </Wrapper>
  </Layout>
)

export default LayoutBrowserDeprecation
