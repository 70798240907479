import styled from 'styled-components'

import { transparentize } from 'polished'
import { Colors } from '../../utils/styles/Color'

const PAGE_SPECIFIC_COLORS = {
  PulseBlue: '#0668D9',
  GrayDark: '#849CB2',
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background:
    "url('https://res.cloudinary.com/pulsedatatools/image/upload/v1642703455/prototypes/ie-deprecation-page/ie-deprecation-bg.svg')", //eslint-disable-line
  backgroundSize: 'cover',
  padding: 96,
})

const Content = styled.div({
  width: 800,
  textAlign: 'center',
})

const Header = styled.div({
  fontSize: 28,
  lineHeight: '38px',
  letterSpacing: 0.4,
  fontWeight: 700,
  color: PAGE_SPECIFIC_COLORS.PulseBlue,
  marginBottom: 32,
})

const SubHeader = styled.div({
  fontSize: 18,
  lineHeight: '32px',
  letterSpacing: 0.4,
  marginBottom: 32,
})

const CardWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 32,
})

const BrowserCard = styled.a({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 150,
  width: 150,
  margin: '0 16px',
  padding: 16,
  borderRadius: 8,
  cursor: 'pointer',
  background: Colors.White,
  ':hover': {
    boxShadow: `0 0 0 8px ${transparentize(
      0.7,
      PAGE_SPECIFIC_COLORS.GrayDark
    )}`,
  },
})

const BrowserName = styled.div({
  fontSize: 18,
  lineHeight: '38px',
  fontWeight: 500,
  color: PAGE_SPECIFIC_COLORS.PulseBlue,
  marginTop: 12,
})

export {
  Wrapper,
  Content,
  Header,
  SubHeader,
  CardWrapper,
  BrowserCard,
  BrowserName,
}
