import React from 'react'

import SEO from '../components/seo'
import LayoutBrowserDeprecation from '../pageLayouts/LayoutBrowserDeprecation'

const BrowserDeprecationPage = () => (
  <div>
    <SEO title="Browser Deprecation" />
    <LayoutBrowserDeprecation />
  </div>
)

export default BrowserDeprecationPage
